import {defineAsyncComponent} from 'vue';

/**
 * Synchronous exports
 */
export {default as ProductShow} from './components/ProductShow.vue';
export {default as ProductRecommendations} from './components/ProductRecommendations.vue';
export {default as ProductCrossSell} from './components/ProductCrossSell.vue';
export {default as ProductReviews} from './components/ProductReviews.vue';

export {default as AddToCartPopup} from './components/AddToCartPopup.vue';
export {default as SizeChartPopup} from './components/SizeChartPopup.vue';
export {default as ProductStock} from './components/ProductStock.vue';
export {default as EcoIcons} from './components/EcoIcons.vue';

export {ProductProvider} from './providers/productProvider';

/**
 * Asynchronous exports
 */
export const DesignCrossSeller = defineAsyncComponent(
  () => import('./components/DesignCrossSeller.vue')
);
export const ProductImageCarousel = defineAsyncComponent(
  () => import('./components/ProductImageCarousel.vue')
);
export const ProductUgc = defineAsyncComponent(
  () => import('./components/ProductUgc.vue')
);
export const ProductTaglets = defineAsyncComponent(
  () => import('./components/ProductTaglets.vue')
);
